<template>
  <div id="slate" class="flex-v">
    <header>
      <BackButton fallback="slates" class="backArrow" />
      <h1>{{ slate.label || slate.type || `(No Label)` }}</h1>
    </header>
    <section>
      <h3>{{ slate.contests[0] && slate.contests[0].type }}</h3>
      <p>
        {{ $store.getters.season }},<br />Week {{ $store.state.seasonWeek }}
      </p>
    </section>
    <RouterView :gen-params.sync="genParams" />
  </div>
</template>

<script>
import BackButton from '../components/BackButton.vue';

export default {
  name: 'Slate',
  components: {
    BackButton
  },
  data() {
    return {
      genParams: undefined
    };
  },
  computed: {
    slate() {
      return this.$store.state.selectedSlate;
    }
  }
};
</script>

<style>
#slate {
  height: 100%;
}

#slate > header + section {
  display: flex;
  justify-content: space-between;
}
</style>
