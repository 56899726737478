<template>
  <button title="Go Back" @click="goBack">{{ text }}</button>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    fallback: {
      type: [String, Object],
      required: true
    },
    text: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack() {
      this.$router.go(
        window.history.length - this.$store.state.historyCount == 0
          ? this.fallback
          : -1
      );
    }
  }
};
</script>

<style></style>
